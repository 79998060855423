import React from 'react';

interface Stage {
  label: string;
  key: string;
}

interface AccountProgressBarProps {
  currentAccount?: {
    status?: string;
  };
}

const stages: Stage[] = [
  { label: 'Pending', key: 'Pending' },
  { label: 'Quoted', key: 'Quoted' },
  { label: 'Accepted', key: 'Accepted' },
  { label: 'Install Scheduled', key: 'Install Scheduled' },
  { label: 'Installed', key: 'Installed' },
  { label: 'Complete', key: 'Complete' },
];

const AccountProgressBar: React.FC<AccountProgressBarProps> = ({ currentAccount = {} }) => {
  const getStageColor = (stageKey: string) => {
    const currentStageIndex = stages.findIndex((stage) => stage.key === currentAccount.status);
    const stageIndex = stages.findIndex((stage) => stage.key === stageKey);
    return (currentStageIndex === -1 || stageIndex > currentStageIndex) ? '#ddd' : '#28a745';
  };

  return (
    <div className="w-full">
      <div style={{ 
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        overflowX: 'auto',
        padding: '1rem',
        gap: '-20px'
      }}>
        {stages.map((stage, index) => (
          <div
            key={stage.key}
            style={{
              position: 'relative',
              width: '150px',
              height: '48px',
              flexShrink: 0
            }}
          >
            <div
              style={{
                position: 'absolute',
                inset: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: getStageColor(stage.key),
                color: 'white',
                fontSize: '0.875rem',
                clipPath: 'polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%)',
                transition: 'all 0.3s ease',
                padding: '0 0.5rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {stage.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountProgressBar;