// MobileView.tsx
import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Stack } from '@mui/material';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { AccountCard } from './AccountCard';
import { ViewProps } from 'types/api.types';
import AccountModal from 'components/sales/AccountModal';

export const MobileView: React.FC<ViewProps> = ({
  accounts,
  statusColumns,
  groupedAccounts,
  formatCurrency,
  handleDragEnd,
  handleEditAccount,
  modalOpen,
  setModalOpen,
  accountFormData,
  setAccountFormData,
  handleSave
}) => {
  const [selectedStatus, setSelectedStatus] = React.useState<string>('All');

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Box sx={{ p: 2 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              label="Status"
            >
              <MenuItem value="All">All Accounts</MenuItem>
              {statusColumns.map(status => (
                <MenuItem key={status} value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Droppable droppableId={selectedStatus === 'All' ? 'all' : selectedStatus}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <Stack spacing={2}>
                  {(selectedStatus === 'All' ? accounts : groupedAccounts[selectedStatus] || [])
                    .map((account, index) => (
                      <AccountCard
                        key={account.id}
                        account={account}
                        index={index}
                        onClick={handleEditAccount}
                        formatCurrency={formatCurrency}
                      />
                    ))}
                </Stack>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Box>
      </DragDropContext>

      {modalOpen && (
        <AccountModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleSave}
          formData={accountFormData}
          setFormData={setAccountFormData}
          isNewAccount={false}
        />
      )}
    </>
  );
};