import React from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import logo from '../assets/logo3.png';

const Navbar: React.FC = () => {
  const LOGO_HEIGHT = 75;
  return (
    <AppBar
      position="static"
      color="primary"
      sx={{ 
        height: LOGO_HEIGHT,
        borderRadius: 0, // Remove rounding
        '& .MuiPaper-rounded': { // Ensure no rounding from Paper component
          borderRadius: 0
        }
      }}
    >
      <Toolbar
        sx={{
          height: LOGO_HEIGHT - 25,
          minHeight: LOGO_HEIGHT - 25,
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}
      >
        <IconButton
          component="a"
          href="/"
          disableRipple
          sx={{
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }}
        >
          <img
            src={logo}
            alt="Logo"
            style={{
              height: LOGO_HEIGHT,
              objectFit: 'contain'
            }}
          />
        </IconButton>
        <Typography variant="h6">
          Ensemble HQ
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;