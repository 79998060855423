// src/config/environment.ts
interface Environment {
    API_URL: string;
    isDevelopment: boolean;
  }
  
  const PROD_URL = 'https://api.ensemble-hq.com';
  const DEV_URL = 'https://localhost:7239';
  
  const USE_DEV = true;
  
  export const environment: Environment = {
    API_URL: USE_DEV ? DEV_URL : PROD_URL,
    isDevelopment: USE_DEV
  };
