import React, { useMemo } from 'react';
import {
    Box,
    Paper,
    Typography,
    Stack
} from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Account } from 'types/api.types';

const PRIMARY_COLOR = '#114D69';
const ACCENT_COLOR = '#4FC3F7';

interface StatsDashboardProps {
    accounts: Account[];
}

export const StatsDashboard: React.FC<StatsDashboardProps> = ({ accounts = [] }) => {
    const stats = useMemo(() => {
        const totalAccounts = accounts.length;

        const statusOrder = ['Pending', 'Quoted', 'Accepted', 'Install Scheduled', 'Installed', 'Complete'];
        const statuses = statusOrder.reduce((acc, status) => {
            acc[status] = accounts.filter(account => account.status === status).length;
            return acc;
        }, {} as Record<string, number>);

        const totalRevenue = accounts.reduce((sum, account) =>
            sum + (account.acceptedAmount || account.quote || 0), 0
        );

        const avgQuoteAmount = totalAccounts > 0
            ? accounts.reduce((sum, account) => sum + (account.quote || 0), 0) / totalAccounts
            : 0;

        const totalPaidAccounts = accounts.filter(account => account.paid).length;

        const yearTotals = accounts.reduce((acc, account) => {
            const year = account.createdDate
                ? new Date(account.createdDate).getFullYear().toString()
                : new Date().getFullYear().toString();

            if (!acc[year]) {
                acc[year] = {
                    name: year,
                    accounts: 0,
                    revenue: 0
                };
            }
            acc[year].accounts++;
            acc[year].revenue += account.acceptedAmount || account.quote || 0;
            return acc;
        }, {} as Record<string, { name: string; accounts: number; revenue: number }>);

        const yearlyData = Object.values(yearTotals).sort((a, b) => a.name.localeCompare(b.name));

        return {
            totalAccounts,
            statuses,
            totalRevenue,
            avgQuoteAmount,
            totalPaidAccounts,
            yearlyData
        };
    }, [accounts]);

    return (
        <Stack spacing={2} sx={{ p: 2, height: 'calc(100vh - 112px)' }}>
            {/* First row with both panels */}
            <Stack
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                spacing={2}
                sx={{ flex: 1 }}
            >                {/* Yearly Trends */}
                <Paper
                    sx={{
                        p: 2,
                        bgcolor: PRIMARY_COLOR,
                        color: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        minHeight: { xs: '300px', md: 'auto' }, // Adjust height for smaller screens
                    }}
                    elevation={2}
                >
                    <Typography variant="h6" sx={{ mb: 2 }}>Yearly Trends</Typography>
                    <Box sx={{ flex: 1, minHeight: 0 }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={stats.yearlyData}>
                                <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
                                <XAxis
                                    dataKey="name"
                                    stroke="rgba(255,255,255,0.7)"
                                />
                                <YAxis
                                    yAxisId="left"
                                    stroke="rgba(255,255,255,0.7)"
                                    label={{
                                        value: 'Number of Accounts',
                                        angle: -90,
                                        position: 'insideLeft',
                                        fill: 'rgba(255,255,255,0.7)'
                                    }}
                                />
                                <YAxis
                                    yAxisId="right"
                                    orientation="right"
                                    stroke="rgba(255,255,255,0.7)"
                                    label={{
                                        value: 'Revenue ($)',
                                        angle: 90,
                                        position: 'insideRight',
                                        fill: 'rgba(255,255,255,0.7)'
                                    }}
                                />
                                <Tooltip
                                    contentStyle={{
                                        backgroundColor: PRIMARY_COLOR,
                                        border: '1px solid rgba(255,255,255,0.2)',
                                        color: 'white'
                                    }}
                                    formatter={(value: number) => {
                                        if (typeof value === 'number') {
                                            return value.toLocaleString();
                                        }
                                        return value;
                                    }}
                                />
                                <Line
                                    yAxisId="left"
                                    type="monotone"
                                    dataKey="accounts"
                                    stroke={ACCENT_COLOR}
                                    strokeWidth={2}
                                    name="Accounts"
                                />
                                <Line
                                    yAxisId="right"
                                    type="monotone"
                                    dataKey="revenue"
                                    stroke="#81C784"
                                    strokeWidth={2}
                                    name="Revenue"
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                </Paper>

                {/* Status Distribution */}
                <Paper
                    sx={{
                        p: 2,
                        bgcolor: PRIMARY_COLOR,
                        color: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        minHeight: { xs: '300px', md: 'auto' }, // Adjust height for smaller screens
                    }}
                    elevation={2}
                >
                    <Typography variant="h6" sx={{ mb: 2 }}>Status Distribution</Typography>
                    <Box sx={{ flex: 1, overflowY: 'auto', minHeight: 0 }}>
                        {Object.entries(stats.statuses).map(([status, count]) => (
                            <Box key={status} sx={{ mb: 2 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                    <Typography variant="body2">
                                        {status}
                                    </Typography>
                                    <Typography variant="body2">
                                        {count} ({Math.round((count / stats.totalAccounts) * 100)}%)
                                    </Typography>
                                </Box>
                                <Box sx={{ width: '100%', bgcolor: 'rgba(255,255,255,0.1)', borderRadius: 1 }}>
                                    <Box
                                        sx={{
                                            width: `${(count / stats.totalAccounts) * 100}%`,
                                            height: 8,
                                            bgcolor: ACCENT_COLOR,
                                            borderRadius: 1
                                        }}
                                    />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Paper>
            </Stack>

            {/* Row 2: Stats Summary */}
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                sx={{ height: { xs: 'auto', sm: '120px' } }} // Adjust height for smaller screens
            >                <Paper
                sx={{
                    p: 2,
                    bgcolor: PRIMARY_COLOR,
                    color: 'white',
                    flex: 1,
                    mb: { xs: 2, sm: 0 }, // Add margin for vertical layout on smaller screens
                }}
                elevation={2}
            >
                    <Typography variant="subtitle2" color="rgba(255,255,255,0.7)">
                        Total Accounts
                    </Typography>
                    <Typography variant="h4">{stats.totalAccounts}</Typography>
                </Paper>

                <Paper
                    sx={{ p: 2, bgcolor: PRIMARY_COLOR, color: 'white', flex: 1 }}
                    elevation={2}
                >
                    <Typography variant="subtitle2" color="rgba(255,255,255,0.7)">
                        Total Revenue
                    </Typography>
                    <Typography variant="h4">
                        ${stats.totalRevenue.toLocaleString()}
                    </Typography>
                </Paper>

                <Paper
                    sx={{ p: 2, bgcolor: PRIMARY_COLOR, color: 'white', flex: 1 }}
                    elevation={2}
                >
                    <Typography variant="subtitle2" color="rgba(255,255,255,0.7)">
                        Paid Accounts
                    </Typography>
                    <Typography variant="h4">
                        {stats.totalPaidAccounts}
                    </Typography>
                </Paper>

                <Paper
                    sx={{ p: 2, bgcolor: PRIMARY_COLOR, color: 'white', flex: 1 }}
                    elevation={2}
                >
                    <Typography variant="subtitle2" color="rgba(255,255,255,0.7)">
                        Average Quote
                    </Typography>
                    <Typography variant="h4">
                        ${Math.round(stats.avgQuoteAmount).toLocaleString()}
                    </Typography>
                </Paper>
            </Stack>
        </Stack>
    );
};

export default StatsDashboard;
