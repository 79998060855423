import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, MenuItem, Paper, Select, Typography } from '@mui/material';
import { ArrowForward, Check, Settings } from '@mui/icons-material';
import MapSection from '../MapSection';
import InstallDetailsModal from './InstallDetailsModal';
import InstallSettingsModal from './InstallSettingsModal';
import { Account, LightAccountFormData, User } from 'types/api.types';
import { updateAccount } from 'contexts/api';
import CompleteInstallModal from './CompleteInstallModal';

interface InstallerTabProps {
    accounts: Account[];
    user: User;
    handleAccountUpdate: (updatedAccount: Account) => void;
}

const getDateRangeFilter = (range: string) => {
    const now = new Date();
    const startDate = new Date();

    switch (range) {
        case 'Weekly':
            startDate.setDate(now.getDate() - 7);
            return startDate;
        case 'Monthly':
            startDate.setMonth(now.getMonth() - 1);
            return startDate;
        case 'Yearly':
            startDate.setFullYear(now.getFullYear() - 1);
            return startDate;
        default:
            return new Date(0);
    }
};

const filterAccountsByDateRange = (accountList: Account[], range: string) => {
    const startDate = getDateRangeFilter(range);

    return accountList.filter(account => {
        const accountDate = account.status === 'Complete' || account.status === 'Installed'
            ? new Date(account.installDate || 0)
            : new Date(account.paymentDueDate || 0);

        return accountDate >= startDate;
    });
};

const InstallerTab: React.FC<InstallerTabProps> = ({ accounts, user, handleAccountUpdate }) => {
    const [mapAccounts, setMapAccounts] = useState<Account[]>([]);
    const [center, setCenter] = useState<{ lat: number; lng: number } | null>(null);
    const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedInstall, setSelectedInstall] = useState<string | null>(null);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [selectedRange, setSelectedRange] = useState<string>('All Time');
    const [completeModalOpen, setCompleteModalOpen] = useState(false);
    const [isCompletingInstall, setIsCompletingInstall] = useState(false);
    const [completeError, setCompleteError] = useState<string | null>(null);

    const isUserInstallerForAccount = (account: Account, userId: string) => {
        return account.installers.some(installer => installer.id === userId);
    };

    const handleMarkComplete = async () => {
        if (!selectedInstall || !selectedAccount) return;

        setIsCompletingInstall(true);
        setCompleteError(null);

        try {
            const formData: Partial<LightAccountFormData> = {
                status: 'Installed'
            };

            const updatedAccount = await updateAccount(selectedInstall, formData as LightAccountFormData);
            handleAccountUpdate(updatedAccount);
            setCompleteModalOpen(false);

        } catch (error) {
            setCompleteError(error instanceof Error ? error.message : 'Failed to mark installation as complete');
        } finally {
            setIsCompletingInstall(false);
        }
    };

    useEffect(() => {
        const filteredAccounts = filterAccountsByDateRange(accounts, selectedRange);
        const futureInstalls = filteredAccounts.filter((account) =>
            (account.status === 'Install Scheduled' || account.status === 'Accepted') &&
            isUserInstallerForAccount(account, user.id)
        );

        setMapAccounts(futureInstalls);

        if (futureInstalls.length > 0) {
            const firstInstall = futureInstalls[0];
            setCenter({ lat: firstInstall.lat, lng: firstInstall.lng });
        }
    }, [accounts, selectedRange, user.id]);

    const handleAccountClick = (account: Account) => {
        setSelectedAccount(account);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedAccount(null);
    };

    const handleSaveSettings = (newRange: string) => {
        setSelectedRange(newRange);
        setSettingsOpen(false);
    };

    const navigateToSelectedAccount = () => {
        if (!selectedInstall) return;
        const account = mapAccounts.find((p) => p.id === selectedInstall);
        if (account) {
            setCenter({ lat: account.lat, lng: account.lng });
        }
    };

    const filteredAccounts = filterAccountsByDateRange(accounts, selectedRange);
    const completedInstalls = filteredAccounts.filter(account =>
        (account.status === 'Complete' || account.status === 'Installed') &&
        isUserInstallerForAccount(account, user.id)
    ).length;

    const upcomingInstalls = filteredAccounts.filter(account =>
        (account.status === 'Install Scheduled' || account.status === 'Accepted') &&
        isUserInstallerForAccount(account, user.id)
    ).length;

    return (
        <Box sx={{ p: { xs: 1, sm: 2, md: 3 } }}>
            {/* Top Section: User Email and Settings Button */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: { xs: 1, sm: 2 } }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white' }}>
                    My Installs ({selectedRange})
                </Typography>
                <IconButton
                    onClick={() => setSettingsOpen(true)}
                    sx={{
                        bgcolor: '#252423',
                        color: '#fff',
                        borderRadius: '50%',
                        '&:hover': { bgcolor: '#333' },
                    }}
                >
                    <Settings />
                </IconButton>
            </Box>

            {/* Statistics Section */}
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: { xs: 'repeat(2, min-content)', sm: 'repeat(4, min-content)' },
                gap: { xs: 1, sm: 2 },
                mb: { xs: 2, sm: 3 },
                justifyContent: 'center'
            }}>
                <Paper elevation={2} sx={{ p: { xs: 1, sm: 2 }, minWidth: { xs: '110px', sm: '140px' }, textAlign: 'center' }}>
                    <Typography variant="subtitle2">Upcoming</Typography>
                    <Typography variant="h6">{upcomingInstalls}</Typography>
                </Paper>
                <Paper elevation={2} sx={{ p: { xs: 1, sm: 2 }, minWidth: { xs: '110px', sm: '140px' }, bgcolor: '#4caf50', color: '#ffffff', textAlign: 'center' }}>
                    <Typography variant="subtitle2">Completed</Typography>
                    <Typography variant="h6">{completedInstalls}</Typography>
                </Paper>
                <Paper elevation={2} sx={{ p: { xs: 1, sm: 2 }, minWidth: { xs: '110px', sm: '140px' }, textAlign: 'center' }}>
                    <Typography variant="subtitle2">Unearned</Typography>
                    <Typography variant="h6">
                        ${(user?.unearnedInstalls || 0).toFixed(2)}
                    </Typography>
                </Paper>
                <Paper elevation={2} sx={{ p: { xs: 1, sm: 2 }, minWidth: { xs: '110px', sm: '140px' }, bgcolor: '#4caf50', color: '#ffffff', textAlign: 'center' }}>
                    <Typography variant="subtitle2">Earned</Typography>
                    <Typography variant="h6">
                        ${(user?.installerEarned || 0).toFixed(2)}
                    </Typography>
                </Paper>
            </Box>

            {/* Selector Section */}
            <Box sx={{ display: 'flex', gap: 1, mb: { xs: 2, sm: 3 } }}>
                <Select
                    value={selectedInstall || ''}
                    onChange={(e) => setSelectedInstall(e.target.value as string)}
                    fullWidth
                    size="small"
                    displayEmpty
                    sx={{
                        bgcolor: '#ffffff',
                        height: '36px',
                        '& .MuiOutlinedInput-input': {
                            padding: '8px 14px',
                        }
                    }}
                >
                    <MenuItem value="" disabled>Select an installation</MenuItem>
                    {mapAccounts
                        .sort((a, b) => new Date(a.installDate || 0).getTime() - new Date(b.installDate || 0).getTime())
                        .map((account) => (
                            <MenuItem key={account.id} value={account.id}>
                                {account.customerName || 'Unnamed'} -{' '}
                                {account.installDate ? new Date(account.installDate).toLocaleDateString() : 'No Date'}
                            </MenuItem>
                        ))}
                </Select>
                <Button
                    variant="contained"
                    onClick={navigateToSelectedAccount}
                    disabled={!selectedInstall}
                    sx={{
                        minWidth: 36,
                        width: 36,
                        height: 36,
                        p: 0
                    }}
                >
                    <ArrowForward sx={{ fontSize: 20 }} />
                </Button>
                <Button
                    variant="contained"
                    onClick={() => setCompleteModalOpen(true)}
                    disabled={!selectedInstall || isCompletingInstall || !selectedAccount}
                    sx={{
                        minWidth: 36,
                        width: 36,
                        height: 36,
                        p: 0,
                        bgcolor: '#4caf50',
                        '&:hover': {
                            bgcolor: 'success.dark',
                        }
                    }}
                >
                    <Check sx={{ fontSize: 20 }} />
                </Button>
            </Box>

            {completeError && (
                <Typography
                    color="error"
                    sx={{ mt: 2, mb: 2 }}
                >
                    {completeError}
                </Typography>
            )}

            {/* Map Section */}
            <Box sx={{
                height: { xs: '300px', sm: '400px' },
                width: '100%',
                border: '2px solid #1d85b5',
                borderRadius: 1,
                overflow: 'hidden',
                position: 'relative'
            }}>
                <MapSection
                    pins={mapAccounts}
                    center={center || { lat: 40.7128, lng: -74.006 }}
                    onMapClick={() => { }}
                    onMarkerClick={handleAccountClick}
                />
            </Box>

            {/* MODALS */}
            <InstallDetailsModal
                open={modalOpen}
                account={selectedAccount}
                onClose={handleCloseModal}
            />

            <InstallSettingsModal
                open={settingsOpen}
                onClose={() => setSettingsOpen(false)}
                onSave={handleSaveSettings}
            />

            <CompleteInstallModal
                open={completeModalOpen}
                onClose={() => setCompleteModalOpen(false)}
                onComplete={handleMarkComplete}
                account={selectedAccount}
            />
        </Box >
    );
};

export default InstallerTab;