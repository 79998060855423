// components/installs/CompleteInstallModal.tsx
import React, { useState } from 'react';
import { 
  Box, 
  Button, 
  Modal, 
  Typography
} from '@mui/material';
import { Account } from 'types/api.types';

interface CompleteInstallModalProps {
  open: boolean;
  onClose: () => void;
  onComplete: () => Promise<void>;
  account: Account | null;
}

const CompleteInstallModal: React.FC<CompleteInstallModalProps> = ({
  open,
  onClose,
  onComplete,
  account
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleComplete = async () => {
    setIsSubmitting(true);
    setError(null);
    try {
      await onComplete();
      onClose();
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to mark installation as complete');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box sx={{
        backgroundColor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 1,
        width: '90%',
        maxWidth: 400,
      }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Mark Installation as Complete
        </Typography>
        
        <Typography variant="body1" sx={{ mb: 2 }}>
          Are you sure you want to mark this installation as complete?
        </Typography>

        <Typography variant="body2" sx={{ mb: 3 }}>
          Customer: {account?.customerName}<br />
          Address: {account?.address}
        </Typography>

        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
          <Button 
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button 
            variant="contained" 
            onClick={handleComplete}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Marking Complete...' : 'Mark Complete'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CompleteInstallModal;