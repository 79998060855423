import React from 'react';
import { Box, Modal, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Account } from 'types/api.types';

interface InstallDetailsModalProps {
    open: boolean;
    account: Account | null;
    onClose: () => void;
}

const InstallDetailsModal: React.FC<InstallDetailsModalProps> = ({ open, account, onClose }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="install-details-title"
            aria-describedby="install-details-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: '#252423',
                    color: '#ffffff',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    width: '600px',
                    maxWidth: '90vw',
                }}
            >
                {/* Header Section */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        mb: 2,
                    }}
                >
                    <Typography
                        id="install-details-title"
                        variant="h5"
                        sx={{
                            color: '#1d85b5',
                            textAlign: 'center',
                        }}
                    >
                        {account?.customerName?.toUpperCase() || 'Install Details'}
                    </Typography>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            color: '#ffffff',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Content Section */}
                {account ? (
                    <Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body1" sx={{ color: '#1d85b5' }}>
                                <strong>Address</strong>
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 0.5, wordBreak: 'break-word' }}>
                                {account.address || 'Not provided'}
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body1" sx={{ color: '#1d85b5' }}>
                                <strong>Phone Number</strong>
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 0.5, wordBreak: 'break-word' }}>
                                {account.phoneNumber || 'Not provided'}
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body1" sx={{ color: '#1d85b5' }}>
                                <strong>Status</strong>
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 0.5 }}>
                                {account.status || 'Not available'}
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body1" sx={{ color: '#1d85b5' }}>
                                <strong>Feet of Lights</strong>
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 0.5 }}>
                                {account.feetOfLights || 0}
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body1" sx={{ color: '#1d85b5' }}>
                                <strong>Scheduled Date</strong>
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 0.5 }}>
                                {account.installDate
                                    ? new Date(account.installDate).toLocaleDateString()
                                    : 'Not Scheduled'}
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body1" sx={{ color: '#1d85b5' }}>
                                <strong>Notes</strong>
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 0.5 }}>
                                {account.notes || 'No notes available'}
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <Typography variant="body1">No details available.</Typography>
                )}
            </Box>
        </Modal>
    );
};

export default InstallDetailsModal;
