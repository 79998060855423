// AdminDashboard.tsx
import React, { useState } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { DropResult } from '@hello-pangea/dnd';
import { DesktopView } from './DesktopView';
import { MobileView } from './MobileView';
import { TabletView } from './TabletView';
import { updateAccount } from 'contexts/api';
import { Account, AdminDashboardProps, LightAccountFormData } from 'types/api.types';

const AdminDashboard: React.FC<AdminDashboardProps> = ({ accounts, onAccountUpdate, handleDeleteAccount }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  
  const [modalOpen, setModalOpen] = useState(false);
  const [currentAccount, setCurrentAccount] = useState<Account | null>(null);
  const [accountFormData, setAccountFormData] = useState<LightAccountFormData>({
    status: '',
    customerName: '',
    address: '',
    feetOfLights: '',
    trackColor: '',
    phoneNumber: '',
    email: '',
    description: '',
    notes: '',
    quotePerFt: 0,
    quote: 0,
    acceptedPerFt: 0,
    acceptedAmount: 0,
    paid: false,
    materialStatus: ''
  });

  const statusColumns = ['Pending', 'Quoted', 'Accepted', 'Install Scheduled', 'Installed', 'Complete'];

  const groupedAccounts = statusColumns.reduce((acc, status) => {
    acc[status] = accounts.filter(account => account.status === status);
    return acc;
  }, {} as Record<string, Account[]>);

  const getColumnStats = (columnAccounts: Account[]) => ({
    total: columnAccounts.reduce((sum, acc) => sum + (acc.acceptedAmount || acc.quote || 0), 0),
    count: columnAccounts.length
  });

  const formatCurrency = (amount: number) => 
    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);

  const handleDragEnd = async (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const account = accounts.find(a => a.id === draggableId);
    if (!account) return;

    const updatedAccount = {
      ...account,
      status: destination.droppableId
    };

    // Optimistic update
    onAccountUpdate(updatedAccount);

    try {
      const formData: Partial<LightAccountFormData> = {
        status: destination.droppableId
      };

      await updateAccount(account.id, formData as LightAccountFormData);
    } catch (error) {
      console.error('Failed to update account status:', error);
      // Revert on failure
      onAccountUpdate({
        ...updatedAccount,
        status: source.droppableId
      });
    }
  };

  const handleEditAccount = (account: Account) => {
    setCurrentAccount(account);
    setAccountFormData({
      status: account.status || '',
      customerName: account.customerName || '',
      address: account.address || '',
      feetOfLights: account.feetOfLights?.toString() || '',
      trackColor: account.trackColor || '',
      phoneNumber: account.phoneNumber || '',
      email: account.email || '',
      description: account.description || '',
      notes: account.notes || '',
      quotePerFt: account.quotePerFt || 0,
      quote: account.quote || 0,
      acceptedPerFt: account.acceptedPerFt || 0,
      acceptedAmount: account.acceptedAmount || 0,
      paid: account.paid || false,
      materialStatus: account.materialStatus || ''
    });
    setModalOpen(true);
  };

  const handleSave = async () => {
    if (!currentAccount) return;

    try {
      const updatedAccount = await updateAccount(currentAccount.id, accountFormData);
      onAccountUpdate(updatedAccount);
      setModalOpen(false);
    } catch (error) {
      console.error('Error updating account:', error);
      throw error;
    }
  };

  const viewProps = {
    accounts,
    onAccountUpdate,
    statusColumns,
    groupedAccounts,
    getColumnStats,
    formatCurrency,
    handleDragEnd,
    handleEditAccount,
    modalOpen,
    setModalOpen,
    currentAccount,
    accountFormData,
    setAccountFormData,
    handleSave,
    handleDeleteAccount,
  };

  if (isMobile) return <MobileView {...viewProps} />;
  if (isTablet) return <TabletView {...viewProps} />;
  return <DesktopView {...viewProps} />;
};

export default AdminDashboard;