import React, { useEffect, useState } from 'react';
import { Box, Typography, Modal, TextField, Button, Select, MenuItem, FormControl, IconButton } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../themes/default';
import { Close } from '@mui/icons-material';

export interface AccountFormData {
  status: string;
  customerName: string;
  address: string;
  feetOfLights: string;
  trackColor: string;
  phoneNumber: string;
  notes: string;
  email: string;
  description: string;
  quotePerFt: number;
  quote: number;
  acceptedPerFt: number;
  acceptedAmount: number;
  paid: boolean;
  materialStatus: string;
}

interface AccountModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  formData: AccountFormData;
  setFormData: React.Dispatch<React.SetStateAction<AccountFormData>>;
  isNewAccount: boolean;
  paymentStatus?: string;
  employeePaymentStatus?: string;
}

const AccountModal: React.FC<AccountModalProps> = ({ open, onClose, onSave, formData, setFormData, isNewAccount, paymentStatus, employeePaymentStatus }) => {
  const predefinedStatuses = ['Pending', 'Quoted', 'Accepted', 'Install Scheduled', 'Installed', 'Complete'];
  const predefinedMaterialStatuses = ['Ordered', 'InHand'];
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [localNumericValues, setLocalNumericValues] = useState({
    quotePerFt: formData.quotePerFt.toString(),
    quote: formData.quote.toString(),
    acceptedPerFt: formData.acceptedPerFt.toString(),
    acceptedAmount: formData.acceptedAmount.toString(),
  });

  useEffect(() => {
    if (open) {
      setLocalNumericValues({
        quotePerFt: formData.quotePerFt.toString(),
        quote: formData.quote.toString(),
        acceptedPerFt: formData.acceptedPerFt.toString(),
        acceptedAmount: formData.acceptedAmount.toString(),
      });
    }
  }, [open, formData]);

  const handleNumericInputChange = (field: keyof AccountFormData, value: string) => {
    setLocalNumericValues((prev) => ({ ...prev, [field]: value }));
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      setFormData((prev) => ({ ...prev, [field]: parsedValue }));
    }
  };

  useEffect(() => {
    if (open && !formData.status) {
      setFormData(prev => ({
        ...prev,
        status: 'Pending'
      }));
    }
  }, [open, formData.status, setFormData]);

  const labelSx = {
    mb: 0.25
  };

  const textFieldSx = {
    mb: 2,
    mt: 0,
    '& .MuiOutlinedInput-root': {
      height: '40px',
      backgroundColor: '#ffffff',
      '& fieldset': {
        borderColor: '#1d85b5',
      },
      '&:hover fieldset': {
        borderColor: '#1d85b5',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1d85b5',
      }
    },
    '&.MuiTextField-multiline .MuiOutlinedInput-root': {
      height: 'auto'
    }
  };

  const multilineTextFieldSx = {
    mb: 2,
    mt: 0,
    '& .MuiOutlinedInput-root': {
      minHeight: '150px',
      backgroundColor: '#ffffff',
      '& fieldset': {
        borderColor: '#1d85b5',
      },
      '&:hover fieldset': {
        borderColor: '#1d85b5',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1d85b5',
      }
    }
  };

  const handleSaveClick = async () => {
    try {
      setError(null);
      setIsSaving(true);
      await onSave();
    } catch (error) {
      setError(error instanceof Error ? error.message : 'An error occurred while saving');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={open}
        onClose={onClose}
        sx={{
          overflow: 'auto',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            width: {
              xs: '90%',
              sm: 400
            },
            bgcolor: 'background.default',
            border: '2px solid #000',
            boxShadow: 24,
            p: 3,
            my: 2,
            maxHeight: {
              xs: '95vh'
            },
            overflow: 'auto',
            position: 'relative',
            borderRadius: 1,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" component="h2" color="text.primary">
                Add/Edit Account Details
              </Typography>
              <IconButton
                onClick={onClose}
                sx={{
                  color: 'text.primary',
                  p: 0.5
                }}
              >
                <Close />
              </IconButton>
            </Box>

            <Typography variant="subtitle2" sx={labelSx}>Customer Name</Typography>
            <TextField
              fullWidth
              placeholder="John Smith"
              value={formData.customerName}
              onChange={(e) => setFormData({ ...formData, customerName: e.target.value })}
              sx={textFieldSx}
            />

            <Typography variant="subtitle2" sx={labelSx}>Status</Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Select
                value={formData.status}
                onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                sx={{
                  height: '40px',
                  backgroundColor: '#ffffff',
                  '.MuiOutlinedInput-notchedOutline': { borderColor: '#1d85b5' },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#1d85b5' },
                }}
              >
                {predefinedStatuses.map((status) => (
                  <MenuItem key={status} value={status}>{status}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography variant="subtitle2" sx={labelSx}>Material Status</Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Select
                value={formData.materialStatus}
                onChange={(e) => setFormData({ ...formData, materialStatus: e.target.value })}
                sx={{
                  height: '40px',
                  backgroundColor: '#ffffff',
                  '.MuiOutlinedInput-notchedOutline': { borderColor: '#1d85b5' },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#1d85b5' },
                }}
              >
                {predefinedMaterialStatuses.map((materialStatus) => (
                  <MenuItem key={materialStatus} value={materialStatus}>{materialStatus}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography variant="subtitle2" sx={labelSx}>Payment Status</Typography>
            <TextField
              fullWidth
              disabled
              value={paymentStatus || 'Not Available'}
              sx={{
                ...textFieldSx,
                '& .MuiOutlinedInput-root': {
                  ...textFieldSx['& .MuiOutlinedInput-root'],
                  backgroundColor: '#f5f5f5',
                  '& input': {
                    color: 'text.secondary',
                    WebkitTextFillColor: 'unset',
                    cursor: 'default'
                  }
                }
              }}
            />

            <Typography variant="subtitle2" sx={labelSx}>Employee Payment Status</Typography>
            <TextField
              fullWidth
              disabled
              value={employeePaymentStatus || 'UNPAID'}
              sx={{
                ...textFieldSx,
                '& .MuiOutlinedInput-root': {
                  ...textFieldSx['& .MuiOutlinedInput-root'],
                  backgroundColor: '#f5f5f5',
                  '& input': {
                    color: 'text.secondary',
                    WebkitTextFillColor: 'unset',
                    cursor: 'default'
                  }
                }
              }}
            />

            <Typography variant="subtitle2" sx={labelSx}>Address</Typography>
            <TextField
              fullWidth
              placeholder="123 Fake Street"
              value={formData.address}
              onChange={(e) => setFormData({ ...formData, address: e.target.value })}
              sx={textFieldSx}
            />

            <Typography variant="subtitle2" sx={labelSx}>Feet of Lights</Typography>
            <TextField
              fullWidth
              type="number"
              placeholder="100"
              value={formData.feetOfLights}
              onChange={(e) => setFormData({ ...formData, feetOfLights: e.target.value })}
              sx={textFieldSx}
            />

            <Typography variant="subtitle2" sx={labelSx}>Track Color</Typography>
            <TextField
              fullWidth
              placeholder="White"
              value={formData.trackColor}
              onChange={(e) => setFormData({ ...formData, trackColor: e.target.value })}
              sx={textFieldSx}
            />

            <Typography variant="subtitle2" sx={labelSx}>Phone Number</Typography>
            <TextField
              fullWidth
              type="tel"
              placeholder="(555) 555-5555"
              value={formData.phoneNumber}
              onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
              sx={textFieldSx}
            />

            <Typography variant="subtitle2" sx={labelSx}>Email</Typography>
            <TextField
              fullWidth
              placeholder="john@doe.com"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              sx={textFieldSx}
            />

            <Typography variant="subtitle2" sx={labelSx}>Quote Per Ft</Typography>
            <TextField
              fullWidth
              type="number"
              placeholder="15.00"
              value={localNumericValues.quotePerFt}
              onChange={(e) => handleNumericInputChange('quotePerFt', e.target.value)}
              sx={textFieldSx}
            />

            <Typography variant="subtitle2" sx={labelSx}>Quote</Typography>
            <TextField
              fullWidth
              type="number"
              placeholder="1500.00"
              value={localNumericValues.quote}
              onChange={(e) => handleNumericInputChange('quote', e.target.value)}
              sx={textFieldSx}
            />

            <Typography variant="subtitle2" sx={labelSx}>Accepted Per Ft</Typography>
            <TextField
              fullWidth
              type="number"
              placeholder="14.00"
              value={localNumericValues.acceptedPerFt}
              onChange={(e) => handleNumericInputChange('acceptedPerFt', e.target.value)}
              sx={textFieldSx}
            />

            <Typography variant="subtitle2" sx={labelSx}>Accepted Amount</Typography>
            <TextField
              fullWidth
              type="number"
              placeholder="1400.00"
              value={localNumericValues.acceptedAmount}
              onChange={(e) => handleNumericInputChange('acceptedAmount', e.target.value)}
              sx={textFieldSx}
            />

            <Typography variant="subtitle2" sx={labelSx}>Notes</Typography>
            <TextField
              fullWidth
              multiline
              rows={6}
              placeholder="Additional details about the installation..."
              value={formData.notes}
              onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
              sx={multilineTextFieldSx}
            />
          </Box>

          {error && (
            <Typography
              color="error"
              sx={{ mt: 2, mb: 2 }}
            >
              {error}
            </Typography>
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 3,
              pt: 2,
              borderTop: '1px solid rgba(0,0,0,0.12)',
              p: 2
            }}
          >
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                onClick={onClose}
                disabled={isSaving}
                sx={{
                  backgroundColor: '#ff3668',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#9c1a3b',
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSaveClick}
                disabled={isSaving}
              >
                {isSaving ? 'Saving...' : isNewAccount ? 'Create' : 'Update'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default AccountModal;