import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  IconButton,
  FormControl,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { updateEmployee } from '../../contexts/api';
import { AVAILABLE_ROLES } from '../../constants/roles';
import { Role, User } from 'types/api.types';

interface ManageEmployeesModalProps {
  open: boolean;
  onClose: () => void;
  users: User[];
  isLoading: boolean;
  onUserUpdated: (user: User) => void;
}

const styles = {
  dialog: {
    backgroundColor: '#1e1e1e',
    color: '#ffffff'
  },
  inputField: {
    backgroundColor: '#2e2e2e',
    marginTop: '2px',
    borderRadius: 1,
    '& .MuiOutlinedInput-root': {
      height: '40px'
    },
    '& .MuiOutlinedInput-input': {
      color: '#000000',
      padding: '8px 14px',
    }
  },
  select: {
    backgroundColor: '#ffffff',
    color: '#000000',
    height: '40px',
    '& .MuiSelect-icon': {
      color: '#000000'
    },
    marginTop: '2px'
  },
  menuItem: {
    backgroundColor: '#ffffff',
    color: '#000000',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  label: {
    marginBottom: '2px'
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1
  }
};

const ManageEmployeesModal: React.FC<ManageEmployeesModalProps> = ({ open, onClose, users, isLoading, onUserUpdated }) => {
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);
  const [editedUser, setEditedUser] = React.useState<User | null>(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (!open) {
      setSelectedUser(null);
      setEditedUser(null);
      setError(null);
      setSuccessMessage(null);
    }
  }, [open]);

  const handleUserSelect = (event: SelectChangeEvent) => {
    const user = users.find(u => u.id === event.target.value);
    setSelectedUser(user || null);
    setEditedUser(user ? { ...user } : null);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!editedUser) return;

    const { name, value } = e.target;
    setEditedUser(prev => {
      if (!prev) return null;
      return {
        ...prev,
        [name]: value
      };
    });
  };

  const handleRolesChange = (event: SelectChangeEvent<string[]>) => {
    if (!editedUser) return;

    const { value } = event.target;
    const selectedIds = typeof value === 'string' ? value.split(',') : value;

    const selectedRoles: Role[] = selectedIds.map(id => ({
      id,
      name: AVAILABLE_ROLES.find(role => role.id === id)?.name || ''
    }));

    setEditedUser(prev => {
      if (!prev) return null;
      return {
        ...prev,
        roles: selectedRoles
      };
    });
  };


  const handlePaymentStructureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!editedUser) return;

    const { name, value } = e.target;
    const field = name.split('.')[1];

    setEditedUser(prev => {
      if (!prev) return null;
      return {
        ...prev,
        paymentStructure: {
          ...prev.paymentStructure,
          [field]: parseFloat(value) || 0
        }
      };
    });
  };

  const handleSave = async () => {
    if (!editedUser) return;
    setIsSaving(true);
    try {
      const result = await updateEmployee(editedUser.id, {
        firstName: editedUser.firstName,
        lastName: editedUser.lastName,
        email: editedUser.email,
        username: editedUser.username,
        phoneNumber: editedUser.phoneNumber,
        roles: editedUser.roles.map(role => role.id),
        paymentStructure: editedUser.paymentStructure
      });

      if (result.success) {
        onUserUpdated(editedUser);
        setSuccessMessage(result.message);
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError('Failed to update employee');
      console.error(err);
    } finally {
      setIsSaving(false);
    }
  };


  const handleCloseSnackbar = () => {
    setError(null);
    setSuccessMessage(null);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{ sx: styles.dialog }}
      >
        {(isLoading || isSaving) && (
          <Box sx={styles.loadingOverlay}>
            <CircularProgress />
          </Box>
        )}

        <DialogTitle>
          Manage Employees
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', right: 8, top: 8, color: 'white' }}
            disabled={isSaving}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ display: 'grid', gap: 2 }}>
            <Box>
              <Typography variant="subtitle2" sx={styles.label}>Select Employee</Typography>
              <FormControl fullWidth>
                <Select
                  value={selectedUser?.id || ''}
                  onChange={handleUserSelect}
                  sx={styles.select}
                  displayEmpty
                  disabled={isLoading || isSaving}
                >
                  <MenuItem value="" sx={styles.menuItem}>
                    <em>Select an employee</em>
                  </MenuItem>
                  {users.map((user) => (
                    <MenuItem
                      key={user.id}
                      value={user.id}
                      sx={styles.menuItem}
                    >
                      {`${user.firstName} ${user.lastName}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {editedUser && (
              <Box sx={{ display: 'grid', gap: 2 }}>
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                  <Box>
                    <Typography variant="subtitle2" sx={styles.label}>First Name</Typography>
                    <TextField
                      name="firstName"
                      value={editedUser.firstName}
                      onChange={handleInputChange}
                      sx={styles.inputField}
                      disabled={isSaving}
                    />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={styles.label}>Last Name</Typography>
                    <TextField
                      name="lastName"
                      value={editedUser.lastName}
                      onChange={handleInputChange}
                      sx={styles.inputField}
                      disabled={isSaving}
                    />
                  </Box>
                </Box>

                <Box>
                  <Typography variant="subtitle2" sx={styles.label}>Email</Typography>
                  <TextField
                    name="email"
                    type="email"
                    value={editedUser.email}
                    onChange={handleInputChange}
                    sx={styles.inputField}
                    disabled={isSaving}
                  />
                </Box>

                <Box>
                  <Typography variant="subtitle2" sx={styles.label}>Username</Typography>
                  <TextField
                    name="username"
                    type="username"
                    value={editedUser.username}
                    onChange={handleInputChange}
                    sx={styles.inputField}
                    disabled={isSaving}
                  />
                </Box>

                <Box>
                  <Typography variant="subtitle2" sx={styles.label}>Phone Number</Typography>
                  <TextField
                    name="phoneNumber"
                    value={editedUser.phoneNumber}
                    onChange={handleInputChange}
                    sx={styles.inputField}
                    disabled={isSaving}
                  />
                </Box>

                <Box>
                  <Typography variant="subtitle2" sx={styles.label}>Roles</Typography>
                  <FormControl fullWidth>
                    <Select
                      multiple
                      value={editedUser.roles.map(role => role.id)}
                      onChange={handleRolesChange}
                      sx={styles.select}
                      disabled={isSaving}
                    >
                      {AVAILABLE_ROLES.map((role) => (
                        <MenuItem
                          key={role.id}
                          value={role.id}
                          sx={styles.menuItem}
                        >
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                  <Box>
                    <Typography variant="subtitle2" sx={styles.label}>Base Price</Typography>
                    <TextField
                      name="paymentStructure.basePrice"
                      type="number"
                      value={editedUser.paymentStructure.basePrice}
                      onChange={handlePaymentStructureChange}
                      sx={styles.inputField}
                      disabled={isSaving}
                      inputProps={{ step: "0.01" }}
                    />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={styles.label}>Base Install Price Per Foot</Typography>
                    <TextField
                      name="paymentStructure.baseInstallPricePerFoot"
                      type="number"
                      value={editedUser.paymentStructure.baseInstallPricePerFoot}
                      onChange={handlePaymentStructureChange}
                      sx={styles.inputField}
                      disabled={isSaving}
                      inputProps={{ step: "0.01" }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={onClose}
            variant="contained"
            sx={{
              backgroundColor: '#ff3668',
              color: 'white',
              '&:hover': {
                backgroundColor: '#9c1a3b',
              },
            }}
            disabled={isSaving}
          >
            Cancel
          </Button>
          {editedUser && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={isSaving}
            >
              {isSaving ? 'Saving...' : 'Save Changes'}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!error || !!successMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={error ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {error || successMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManageEmployeesModal;