import React, { useRef, useEffect, useCallback, useState } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { LoaderOptions } from '@googlemaps/js-api-loader';
import { Box, Button, TextField, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InputAdornment from '@mui/material/InputAdornment';


interface MapSectionProps {
  pins: any[];
  center: { lat: number; lng: number };
  onMapClick: (event: google.maps.MapMouseEvent) => void;
  onMarkerClick: (pin: any) => void;
}

type Library = NonNullable<LoaderOptions['libraries']>[number];

const containerStyle = {
  width: '100%',
  height: '400px',
};

const libraries: Library[] = ['maps', 'marker'];

const MapSection: React.FC<MapSectionProps> = ({ pins, center, onMapClick, onMarkerClick }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries,
    mapIds: ['f8234a8e34f69bf7'],
  });

  const mapRef = useRef<google.maps.Map | null>(null);
  const markersRef = useRef<Map<any, google.maps.marker.AdvancedMarkerElement>>(new Map());
  const [searchAddress, setSearchAddress] = useState('');

  const createMarkers = useCallback(
    (map: google.maps.Map) => {
      const bounds = new google.maps.LatLngBounds();

      pins.forEach((pin) => {
        if (!markersRef.current.has(pin.id)) {
          if (pin.lat >= -90 && pin.lat <= 90 && pin.lng >= -180 && pin.lng <= 180) {
            const marker = new google.maps.marker.AdvancedMarkerElement({
              position: { lat: pin.lat, lng: pin.lng },
              map: map,
              title: pin.notes,
            });

            marker.addListener('click', () => onMarkerClick(pin));

            markersRef.current.set(pin.id, marker);
            bounds.extend({ lat: pin.lat, lng: pin.lng });
          } else {
            console.warn(`Invalid pin coordinates for pin:`, pin);
          }
        }
      });

      markersRef.current.forEach((marker, key) => {
        if (!pins.find((pin) => pin.id === key)) {
          marker.map = null;
          markersRef.current.delete(key);
        }
      });

      if (!bounds.isEmpty()) {
        map.fitBounds(bounds);
      }
    },
    [pins, onMarkerClick]
  );

  useEffect(() => {
    if (isLoaded && mapRef.current) {
      createMarkers(mapRef.current);
    }
  }, [pins, isLoaded, createMarkers]);

  const handleSearch = () => {
    if (!searchAddress || !mapRef.current) return;

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: searchAddress }, (results, status) => {
      if (status === 'OK' && results && results.length > 0) {
        const location = results[0].geometry.location;
        mapRef.current?.panTo(location);
        mapRef.current?.setZoom(14);
      } else {
        console.error('Geocode was not successful for the following reason:', status);
      }
    });
  };

  if (loadError) {
    return <Typography>Error loading Google Maps API.</Typography>;
  }

  if (!isLoaded) {
    return <Typography>Loading Map...</Typography>;
  }

  return (
    <Box flex={2} sx={{ position: 'relative', height: '100%' }}>
      {/* Search bar section */}
      <Box
        display="flex"
        gap={1}
        alignItems="center"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          backgroundColor: '#ffffff',
          borderRadius: 2,
          padding: '4px 8px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          maxWidth: '600px',
          width: 'calc(100% - 16px)',
          margin: '4px',
        }}
      >
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Search for an address"
          size="small"
          value={searchAddress}
          onChange={(e) => setSearchAddress(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="contained"
                  onClick={handleSearch}
                  sx={{
                    minWidth: 32,
                    width: 32,
                    height: 32,
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: 'none',
                  }}
                >
                  <ArrowForwardIcon sx={{ fontSize: 18 }} />
                </Button>
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              height: '40px',
              paddingRight: '4px', // Eliminate extra padding
              borderRadius: '4px',
            },
            '& .MuiOutlinedInput-input': {
              padding: '8px 12px',
            },
            '& .MuiInputAdornment-root': {
              margin: 0, // Remove adornment margin
              height: '100%', // Ensure adornment matches input height
            },
            '& .MuiButtonBase-root': {
              margin: 0, // Remove button margin inside adornment
            },
          }}
        />
      </Box>
  
      {/* Map container */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onClick={onMapClick}
        onLoad={(map) => {
          mapRef.current = map;
          if (pins.length > 0) {
            createMarkers(map);
          }
        }}
        options={{
          mapId: 'f8234a8e34f69bf7',
        }}
      />
    </Box>
  );
};
  

export default MapSection;
