import { createContext, useContext, useState, ReactNode, useEffect, useCallback } from 'react';
import { fetchUserProfile } from '../contexts/api';
import { jwtDecode } from "jwt-decode";
import { LoginResponse, User } from 'types/api.types';

interface DecodedToken {
  name: string;
  userId: string;
  role: string[];
  exp: number;
}

interface AuthContextType {
  user: User | null;
  isLoading: boolean;
  login: (token: LoginResponse) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const initializeUser = useCallback(async (token: string) => {
    try {
      const decoded: Record<string, any> = jwtDecode(token);
      const userData: DecodedToken = {
        name: decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
        userId: decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"],
        role: decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"],
        exp: decoded.exp,
      };

      console.log('Decoded token:', userData);

      const userProfile = await fetchUserProfile();
      console.log('Fetched user:', userProfile);
      const roles = Array.isArray(userProfile.roles) ? userProfile.roles : [];
      const paymentStructure = {
        basePrice: userProfile.paymentStructure?.basePrice || 0,
        baseInstallPricePerFoot: userProfile.paymentStructure?.baseInstallPricePerFoot || 0,
      };

      setUser({
        id: userProfile.id,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        phoneNumber: userProfile.phoneNumber,
        email: userProfile.email,
        username: userProfile.username,
        totalSalesAccepted: userProfile.totalSalesAccepted,
        salesOnTheTable: userProfile.salesOnTheTable,
        salesEarned: userProfile.salesEarned,
        installerEarned: userProfile.installerEarned,
        unearnedInstalls: userProfile.unearnedInstalls,
        roles,
        paymentStructure,
        ownedAccounts: userProfile.ownedAccounts,
        accountsAsInstaller: userProfile.accountsAsInstaller
      });
    } catch (error) {
      console.error("Error initializing user:", error);
      logout();
    }
    finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      initializeUser(token);
    }
  }, [initializeUser]);

  const login = async (loginResponse: LoginResponse) => {
    setIsLoading(true);
    localStorage.setItem('token', loginResponse.token);
    await initializeUser(loginResponse.token);
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    setIsLoading(false);
  };

  return (
    <AuthContext.Provider value={{ user, isLoading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
