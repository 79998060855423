import React from 'react';
import { Box, Typography } from '@mui/material';
import { ColumnHeaderProps } from 'types/api.types';

export const ColumnHeader: React.FC<ColumnHeaderProps> = ({
  status,
  stats,
  formatCurrency
}) => (
  <Box sx={{ 
    p: 2, 
    bgcolor: '#1D85B5',
    color: '#CDEDF6',
  }}>
    <Typography variant="h6" sx={{ 
      mb: 1,
      fontWeight: 600
    }}>
      {status} ({stats.count})
    </Typography>
    <Typography variant="body2" sx={{ color: '#CDEDF6' }}>
      Total: {formatCurrency(stats.total)}
    </Typography>
  </Box>
);