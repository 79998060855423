// TabletView.tsx
import React from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { AccountCard } from './AccountCard';
import { ViewProps } from 'types/api.types';
import AccountModal from 'components/sales/AccountModal';

export const TabletView: React.FC<ViewProps> = ({
  statusColumns,
  groupedAccounts,
  getColumnStats,
  formatCurrency,
  handleDragEnd,
  handleEditAccount,
  modalOpen,
  setModalOpen,
  accountFormData,
  setAccountFormData,
  handleSave
}) => {
  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Box sx={{ p: 2, height: 'calc(100vh - 112px)', overflow: 'auto' }}>
          <Stack spacing={3}>
            {statusColumns.map(status => {
              const columnAccounts = groupedAccounts[status] || [];
              const stats = getColumnStats(columnAccounts);

              return (
                <Box key={status}>
                  <Paper sx={{ p: 2, mb: 2, bgcolor: 'primary.main', color: 'white' }}>
                    <Typography variant="h6">
                      {status} ({stats.count})
                    </Typography>
                    <Typography variant="body2">
                      Total: {formatCurrency(stats.total)}
                    </Typography>
                  </Paper>
                  <Droppable droppableId={status}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <Stack spacing={2}>
                          {columnAccounts.map((account, index) => (
                            <AccountCard
                              key={account.id}
                              account={account}
                              index={index}
                              onClick={handleEditAccount}
                              formatCurrency={formatCurrency}
                            />
                          ))}
                        </Stack>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Box>
              );
            })}
          </Stack>
        </Box>
      </DragDropContext>

      {modalOpen && (
        <AccountModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleSave}
          formData={accountFormData}
          setFormData={setAccountFormData}
          isNewAccount={false}
        />
      )}
    </>
  );
};