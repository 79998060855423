import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Select,
    MenuItem,
    Button,
    CircularProgress,
    Alert,
    FormControl,
    Stack,
    Chip
} from '@mui/material';
import { User, Account } from 'types/api.types';
import { assignInstallerToAccount, removeInstallerFromAccount } from '../../contexts/api';

interface AssignInstallersModalProps {
    open: boolean;
    onClose: () => void;
    accounts: Account[];
    installers: User[];
    onAssignmentComplete?: (updatedAccounts: Account[]) => void;
}

const headerCellStyle = {
    backgroundColor: '#1d85b5',
    color: '#ffffff',
    padding: '16px',
    whiteSpace: 'nowrap'
};

const AssignInstallersModal: React.FC<AssignInstallersModalProps> = ({
    open,
    onClose,
    accounts,
    installers,
    onAssignmentComplete
}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedAssignments, setSelectedAssignments] = useState<Record<string, string>>({});

    const handleAssignment = (accountId: string, installerId: string) => {
        setSelectedAssignments(prev => ({
            ...prev,
            [accountId]: installerId
        }));
    };

    const handleRemoveInstaller = async (accountId: string, installerId: string) => {
        setLoading(true);
        setError(null);

        try {
            const updatedAccount = await removeInstallerFromAccount(accountId, installerId);
            onAssignmentComplete?.([updatedAccount]);
        } catch (err) {
            setError('Failed to remove installer. Please try again.');
            console.error('Error removing installer:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleSaveAssignments = async () => {
        setLoading(true);
        setError(null);

        try {
            const updatedAccounts: Account[] = [];

            for (const [accountId, installerId] of Object.entries(selectedAssignments)) {
                const updatedAccount = await assignInstallerToAccount(accountId, installerId);
                updatedAccounts.push(updatedAccount);
            }

            setSelectedAssignments({});
            onAssignmentComplete?.(updatedAccounts);
            onClose();
        } catch (err) {
            setError('Failed to save assignments. Please try again.');
            console.error('Error saving assignments:', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="assign-installers-modal"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90%',
                maxWidth: 1200,  // Increased from before
                minHeight: '300px',
                maxHeight: '80vh',
                bgcolor: '#1e1e1e',
                border: '2px solid #ffffff',
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
                color: '#ffffff',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
                    Assign Installers to Accounts
                </Typography>

                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                <Paper sx={{
                    flex: 1,
                    width: '100%',
                    minHeight: '400px',
                    backgroundColor: '#1e1e1e',
                    color: '#ffffff',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <TableContainer sx={{
                        flex: 1,
                        width: '100%'
                    }}>
                        <Table stickyHeader sx={{ width: '100%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={headerCellStyle}>Customer Name</TableCell>
                                    <TableCell sx={headerCellStyle}>Address</TableCell>
                                    <TableCell sx={headerCellStyle}>Current Installers</TableCell>
                                    <TableCell sx={headerCellStyle}>Assign Installer</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {accounts.map(account => (
                                    <TableRow key={account.id}>
                                        <TableCell sx={{
                                            color: '#ffffff',
                                            padding: '16px'
                                        }}>{account.customerName}</TableCell>
                                        <TableCell sx={{
                                            color: '#ffffff',
                                            padding: '16px'
                                        }}>{account.address}</TableCell>
                                        <TableCell sx={{
                                            color: '#ffffff',
                                            padding: '16px'
                                        }}>
                                            <Stack direction="row" spacing={1} flexWrap="wrap">
                                                {account.installers?.length ?
                                                    account.installers.map(installer => (
                                                        <Chip
                                                            key={installer.id}
                                                            label={`${installer.firstName} ${installer.lastName}`}
                                                            onDelete={() => handleRemoveInstaller(account.id, installer.id)}
                                                            sx={{
                                                                color: '#ffffff',
                                                                bgcolor: '#1d85b5',
                                                                '& .MuiChip-deleteIcon': {
                                                                    color: '#ffffff',
                                                                    '&:hover': {
                                                                        color: '#ff4444'
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    ))
                                                    :
                                                    <Typography variant="body2" color="text.secondary">
                                                        None
                                                    </Typography>
                                                }
                                            </Stack>
                                        </TableCell>
                                        <TableCell sx={{ padding: '16px' }}>
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId={`installer-select-${account.id}`}
                                                    value={selectedAssignments[account.id] || ''}
                                                    onChange={(e) => handleAssignment(account.id, e.target.value as string)}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {  // Using sx instead of style
                                                                maxHeight: '150px !important',
                                                                backgroundColor: '#1e1e1e',
                                                            }
                                                        },
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'left'
                                                        },
                                                        transformOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'left'
                                                        }
                                                    }}
                                                    sx={{
                                                        color: '#ffffff',
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#ffffff',
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#ffffff',
                                                        },
                                                        '& .MuiSelect-select': {  // Add styles for the select input
                                                            padding: '8px 14px',  // Reduce the padding
                                                            minHeight: '20px !important',  // Reduce minimum height
                                                            lineHeight: '1.2',   // Adjust line height
                                                        }
                                                    }}
                                                >
                                                    <MenuItem value="">
                                                        <em>Select an installer</em>
                                                    </MenuItem>
                                                    {installers
                                                        .filter(installer => !account.installers?.some(i => i.id === installer.id))
                                                        .map(installer => (
                                                            <MenuItem key={installer.id} value={installer.id}>
                                                                {`${installer.firstName} ${installer.lastName}`}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'flex-end',
                    mt: 3,
                    pt: 2
                }}>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        sx={{
                            color: '#ffffff',
                            borderColor: '#ffffff',
                            '&:hover': {
                                borderColor: '#ffffff',
                                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                            },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSaveAssignments}
                        disabled={loading || Object.keys(selectedAssignments).length === 0}
                        sx={{
                            '&.Mui-disabled': {
                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                            },
                        }}
                    >
                        {loading ? (
                            <>
                                <CircularProgress size={20} sx={{ mr: 1 }} />
                                Saving...
                            </>
                        ) : (
                            'Save Assignments'
                        )}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default AssignInstallersModal;