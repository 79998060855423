import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    background: {
      default: '#252423', // App background color
    },
    primary: {
      main: '#1d85b5', // Primary color for buttons, labels, etc.
    },
    text: {
      primary: '#ffffff', // Default text color for general text (white)
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        margin: 'normal',
        fullWidth: true,
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#1d85b5', // Default label color
          '&.Mui-focused': {
            color: '#1d85b5', // Focused label color
          },
          backgroundColor: '#ffffff', // Match the input field background
          padding: '0 4px', // Add padding to prevent label overlap with field edges
          transform: 'translate(14px, 16px) scale(1)', // Properly position label inside the box
        },
        shrink: {
          transform: 'translate(14px, -6px) scale(0.75)', // Shrink label position above the box
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff', // White background for input fields
          borderRadius: 4, // Rounded corners for better aesthetics
          '& fieldset': {
            borderColor: '#1d85b5', // Default border color
          },
          '&:hover fieldset': {
            borderColor: '#1d85b5', // Border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#1d85b5', // Focused border color
          },
        },
        input: {
          padding: '18.5px 14px', // Adjust padding to align text and label perfectly
          color: '#252423', // Input text color
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#ffffff', // Default tab text color
          '&.Mui-selected': {
            color: '#ffffff', // Tab text color when selected
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#1d85b5', // Background color for Paper components
          color: '#ffffff', // Text color for Paper components
          padding: '16px', // Add some padding
          textAlign: 'center', // Center-align content
          borderRadius: '8px', // Optional: Rounded corners for better aesthetics
          width: 'calc(25% - 16px)', // Uniform width for Paper components
          minWidth: '200px', // Optional: Ensure a minimum width
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#ffffff', // Override text color for Typography by default
        },
      },
    },
  },
});


export default theme;
