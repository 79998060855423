import React, { useState } from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Box, 
  Chip, 
  Divider, 
  Stack,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button 
} from '@mui/material';
import { 
  Money, 
  AccessTime, 
  Person, 
  Place,
  Close as CloseIcon 
} from '@mui/icons-material';
import { Draggable } from '@hello-pangea/dnd';
import { AccountCardProps } from 'types/api.types';

// Update the AccountCardProps interface to include onDelete
interface ExtendedAccountCardProps extends AccountCardProps {
  onDelete?: (accountId: string) => Promise<void>;
}

export const AccountCard: React.FC<ExtendedAccountCardProps> = ({
  account,
  index,
  onClick,
  formatCurrency,
  onDelete
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent card click event
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (onDelete) {
      await onDelete(account.id);
    }
    setDeleteDialogOpen(false);
  };

  return (
    <>
      <Draggable draggableId={account.id} index={index}>
        {(provided, snapshot) => (
          <Card
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={() => onClick(account)}
            sx={{
              mb: 1,
              cursor: 'pointer',
              backgroundColor: '#323130 !important',
              color: '#ffffff',
              position: 'relative',
              '&:hover': {
                boxShadow: 6,
                transform: snapshot.isDragging ? 'none' : 'translateY(-2px)',
                transition: 'all 0.2s',
                '& .deleteButton': {
                  opacity: 1,
                }
              },
              ...(snapshot.isDragging && {
                boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
              })
            }}
          >
            {onDelete && (
              <IconButton
                className="deleteButton"
                onClick={handleDeleteClick}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: 'rgba(255, 255, 255, 0.7)',
                  opacity: 0,
                  transition: 'opacity 0.2s',
                  zIndex: 1,
                  padding: '4px',
                  '&:hover': {
                    color: '#ff4444',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  }
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}

            <CardContent>
              <Typography variant="h6" gutterBottom>
                {account.customerName || 'Unnamed Account'}
              </Typography>
           
              <Box sx={{ mb: 1, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                <Chip
                  size="small"
                  label={`${account.feetOfLights || 0} ft`}
                  color="primary"
                />
                {account.paymentStatus && (
                  <Chip
                    size="small"
                    label={`${account.paymentStatus || 0}`}
                    color="success"
                  />
                )}
                {account.employeePaymentStatus && (
                  <Chip
                    size="small"
                    label={`${account.employeePaymentStatus || 0}`}
                    color="success"
                  />
                )}
                {account.materialStatus && (
                  <Chip
                    size="small"
                    label={`Materials ${account.materialStatus || 0}`}
                    color="secondary"
                  />
                )}
              </Box>
              <Divider sx={{ my: 1, borderColor: 'rgba(255, 255, 255, 0.12)' }} />
              <Stack spacing={1}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Money fontSize="small" sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                  <Typography variant="body2">
                    {formatCurrency(account.acceptedAmount || account.quote || 0)}
                  </Typography>
                </Box>
                {account.address && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Place fontSize="small" sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                    <Typography variant="body2" noWrap>
                      {account.address}
                    </Typography>
                  </Box>
                )}
                {account.owner && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Person fontSize="small" sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                    <Typography variant="body2" noWrap>
                      {`${account.owner.firstName || ''} ${account.owner.lastName || ''}`}
                    </Typography>
                  </Box>
                )}
                {account.installDate && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <AccessTime fontSize="small" sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                    <Typography variant="body2">
                      {new Date(account.installDate).toLocaleDateString()}
                    </Typography>
                  </Box>
                )}
              </Stack>
            </CardContent>
          </Card>
        )}
      </Draggable>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: '#323130',
            color: '#ffffff',
          }
        }}
      >
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the account for {account.customerName}? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteDialogOpen(false)}
            sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmDelete}
            sx={{ 
              backgroundColor: '#ff4444',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#ff0000',
              }
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};