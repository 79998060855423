import { Account } from "types/api.types";

export const createDefaultLightAccount = (lat: number, lng: number): Account => ({
  id: '',
  accountOwnerId: '',
  lat,
  lng,
  customerName: '',
  feetOfLights: 0,
  trackColor: '',
  phoneNumber: '',
  status: '',
  notes: '',
  address: '',
  quote: 0,
  installDate: '',
  paymentDueDate: '',
  quotePerFt: 0,
  acceptedPerFt: 0,
  acceptedAmount: 0,
  paid: false,
  createdDate: '',
  modifiedDate: '',
  paymentStatus: '',
  employeePaymentStatus: '',
  owner: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    phoneNumber: '',
    totalSalesAccepted: 0,
    salesOnTheTable: 0,
    salesEarned: 0,
    installerEarned: 0,
    unearnedInstalls: 0,
    roles: [],
    paymentStructure: {
      basePrice: 0,
      baseInstallPricePerFoot: 0
    },
    ownedAccounts: [],
    accountsAsInstaller: []
  },
  installers: []
});
