import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import Navbar from '../components/Navbar';
import { useAuth } from '../contexts/AuthContext';
import { fetchAccounts, addAccount, removeAccount, fetchAllAccounts } from '../contexts/api';
import { createDefaultLightAccount } from '../utils/accountUtils';
import InstallerTab from '../components/installs/InstallerTab';
import AdminPanel from '../components/admin/AdminPanel';
import SalesTab from 'components/sales/SalesTab';
import { Snackbar, Alert } from '@mui/material';
import { Account } from 'types/api.types';
import AdminDashboard from 'components/admin/AdminDashboard';
import StatsDashboard from 'components/admin/StatsDashboard';

type TabItem = { label: string; role: string };

const Dashboard = () => {
  const { user, isLoading } = useAuth();
  const [activeTab, setActiveTab] = useState(0);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [center, setCenter] = useState<{ lat: number; lng: number } | null>(null);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(true);
  const [currentAccount, setCurrentAccount] = useState<Account | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [lightAccountFormData, setAccountFormData] = useState({
    status: '',
    customerName: '',
    address: '',
    feetOfLights: '',
    trackColor: '',
    phoneNumber: '',
    email: '',
    description: '',
    notes: '',
    quotePerFt: 0,
    quote: 0,
    acceptedPerFt: 0,
    acceptedAmount: 0,
    paid: false,
    materialStatus: ''
  });

  const tabs: TabItem[] = [
    user?.roles?.some(role => role.name === 'Sales') && { label: 'Sales', role: 'Sales' },
    user?.roles?.some(role => role.name === 'Installer') && { label: 'Installs', role: 'Installer' },
    user?.roles?.some(role => role.name === 'Admin') && { label: 'Admin', role: 'Admin' },
    user?.roles?.some(role => role.name === 'Admin') && { label: 'Board', role: 'AdminDashboard' },
    user?.roles?.some(role => role.name === 'Admin') && { label: 'Stats', role: 'AdminStatsDashboard' },
  ].filter(Boolean) as TabItem[];

  const isAdmin = user?.roles?.some(role => role.name === 'Admin');
  const salesAccounts = accounts.filter(account => account.owner?.id === user?.id);
  const installerAccounts = accounts.filter(account => 
    account.installers?.some(installer => installer.id === user?.id)
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleDeleteAccount = async (accountId: string) => {
    const previousAccounts = [...accounts];
    
    try {
      await removeAccount(accountId);
      setAccounts((prevAccounts) => 
        prevAccounts.filter((account) => account.id !== accountId)
      );
    } catch (error: any) {
      console.error('Failed to remove Account from server:', error);
      setAccounts(previousAccounts);
      
      const errorMessage = error.response?.data?.error || 
                          error.response?.data?.message ||
                          error.response?.data ||
                          'Failed to delete account';
      
      setErrorMessage(errorMessage);
    }
  };

  useEffect(() => {
    const loadAccounts = async () => {
      if (!user || isLoading) return;
      
      setIsLoadingAccounts(true);
      try {
        const fetchedAccounts = isAdmin 
          ? await fetchAllAccounts()
          : await fetchAccounts();
          console.log('Fetched accounts:', fetchedAccounts.length, fetchedAccounts);
        setAccounts(fetchedAccounts);
      } catch (error) {
        console.error('Error fetching accounts:', error);
        setErrorMessage('Failed to load accounts');
      } finally {
        setIsLoadingAccounts(false);
      }
    };

    loadAccounts();
  }, [user, isLoading, isAdmin]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Error fetching location:', error);
          setCenter({
            lat: 40.7128,
            lng: -74.0060,
          });
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      setCenter({
        lat: 40.7128,
        lng: -74.0060,
      });
    }
  }, []);

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const newAccount = createDefaultLightAccount(event.latLng.lat(), event.latLng.lng());
      setCurrentAccount(newAccount);
      setAccountFormData({
        status: '',
        customerName: '',
        address: '',
        feetOfLights: '',
        trackColor: '',
        phoneNumber: '',
        email: '',
        description: '',
        notes: '',
        quotePerFt: 0,
        quote: 0,
        acceptedPerFt: 0,
        acceptedAmount: 0,
        paid: false,
        materialStatus: ''
      });

      setModalOpen(true);
    }
  };

  const handleAccountClick = (account: Account | null) => {
    if (!account) {
      setCurrentAccount(null);
    }
    else {
      setCenter({ lat: account.lat, lng: account.lng });
      setCurrentAccount(account);
    }
  };

  const handleAccountUpdate = (updatedAccount: Account) => {
    setAccounts((prevAccounts) =>
      prevAccounts.map((Account) => (Account.id === updatedAccount.id ? updatedAccount : Account))
    );

    setCurrentAccount(updatedAccount);
  };

  const handleSave = async () => {
    if (!currentAccount || !user) return;

    const newAccount: Account = {
      ...currentAccount,
      ...lightAccountFormData,
      id: `temp-${Date.now()}`,
      owner: user,
      installers: [],
      feetOfLights: parseInt(lightAccountFormData.feetOfLights, 10) || 0,
      quote: parseFloat(lightAccountFormData.quote.toString()) || 0,
      acceptedAmount: parseFloat(lightAccountFormData.acceptedAmount.toString()) || 0
    };

    setAccounts((prevAccounts) => [...prevAccounts, newAccount]);
    setModalOpen(false);
    setCurrentAccount(newAccount);

    try {
      await addAccount(newAccount);
    } catch (error) {
      console.error('Error saving Account:', error);
      setAccounts((prevAccounts) => prevAccounts.filter((Account) => Account.id !== newAccount.id));
      setCurrentAccount(null);
    }
  };

  if (!user || isLoading || isLoadingAccounts) {    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Redirecting to login...
        </Typography>
      </Box>
    );
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Navbar />

      {/* TABS */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          {tabs.map((tab) => (
            <Tab key={tab.role} label={tab.label} />
          ))}
        </Tabs>
      </Box>

      {/* SALES TAB */}
      <Box hidden={tabs[activeTab]?.role !== 'Sales'}>
        <SalesTab
          accounts={salesAccounts}
          currentAccount={currentAccount}
          center={center}
          modalOpen={modalOpen}
          lightAccountFormData={lightAccountFormData}
          handleMapClick={handleMapClick}
          handleAccountClick={handleAccountClick}
          handleAccountUpdate={handleAccountUpdate}
          handleDeleteAccount={handleDeleteAccount}
          setModalOpen={setModalOpen}
          setAccountFormData={setAccountFormData}
          user={user}
          isNewAccount={!currentAccount?.id || currentAccount.id.startsWith('temp-')}
          onSaveNew={handleSave}
        />
      </Box>

      {/* INSTALLS TAB */}
      <Box hidden={tabs[activeTab]?.role !== 'Installer'}>
        <InstallerTab
          accounts={installerAccounts}
          user={user}
          handleAccountUpdate={(updatedAccount) => {
            setAccounts(prevAccounts =>
              prevAccounts.map(account =>
                account.id === updatedAccount.id ? updatedAccount : account
              )
            );
          }}
        />      </Box>

      {/* ADMIN TAB */}
      <Box hidden={tabs[activeTab]?.role !== 'Admin'}>
        <AdminPanel
          accounts={accounts}
          onAccountUpdate={(updatedAccount) => {
            setAccounts(prevAccounts =>
              prevAccounts.map(account =>
                account.id === updatedAccount.id ? updatedAccount : account
              )
            );
          }}
        />
      </Box>

{/* ADMIN DASHBOARD TAB */}
<Box hidden={tabs[activeTab]?.role !== 'AdminDashboard'}>
  <AdminDashboard
    accounts={accounts}
    onAccountUpdate={(updatedAccount) => {
      setAccounts(prevAccounts =>
        prevAccounts.map(account =>
          account.id === updatedAccount.id ? updatedAccount : account
        )
      );
    }}
    handleDeleteAccount={handleDeleteAccount}
  />
</Box>

      {/* ADMIN STATS DASHBOARD TAB */}
      <Box hidden={tabs[activeTab]?.role !== 'AdminStatsDashboard'}>
        <StatsDashboard
          accounts={accounts}
        />
      </Box>

      <Snackbar 
      open={errorMessage !== null} 
      autoHideDuration={6000} 
      onClose={() => setErrorMessage(null)}
    >
      <Alert 
        onClose={() => setErrorMessage(null)} 
        severity="error" 
        sx={{ width: '100%' }}
      >
        {errorMessage}
      </Alert>
    </Snackbar>
    </>
  );
};

export default Dashboard;
