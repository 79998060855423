import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    Box
} from '@mui/material';
import theme from 'themes/default';

interface SettingsModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (selectedRange: string) => void;
}

const SalesSettingsModal: React.FC<SettingsModalProps> = ({ open, onClose, onSave }) => {
    const [selectedRange, setSelectedRange] = useState<string>('All Time');

    const handleRangeChange = (
        event: React.MouseEvent<HTMLElement>,
        newRange: string | null
    ) => {
        if (newRange !== null) {
            setSelectedRange(newRange);
        }
    };

    const buttonSx = {
        flex: 1,
        color: 'white',
        padding: '8px',
        '&.Mui-selected': {
            backgroundColor: '#4eb574',
            color: 'white',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '4px 0',
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiDialog-paper': {
                    backgroundColor: '#252423',
                    width: '100%',
                    maxWidth: '600px',
                    [theme.breakpoints.down('sm')]: {
                        width: '90%',
                        margin: '16px',
                    },
                },
            }}
        >
            <DialogTitle sx={{ color: 'white' }}>Sales Config</DialogTitle>
            <DialogContent sx={{ color: 'white' }}>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" sx={{ color: 'white', mb: 1 }}>
                        Stats Range
                    </Typography>
                    <ToggleButtonGroup
                        value={selectedRange}
                        exclusive
                        onChange={handleRangeChange}
                        orientation={window.innerWidth < 600 ? 'vertical' : 'horizontal'}
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            width: '100%',
                            '& .MuiToggleButtonGroup-grouped': {
                                border: '1px solid rgba(255, 255, 255, 0.12) !important',
                                borderRadius: '4px !important',
                                margin: { xs: '4px 0', sm: '0' },
                            }
                        }}
                    >
                        <ToggleButton value="Weekly" sx={buttonSx}>
                            Weekly
                        </ToggleButton>
                        <ToggleButton value="Monthly" sx={buttonSx}>
                            Monthly
                        </ToggleButton>
                        <ToggleButton value="Yearly" sx={buttonSx}>
                            Yearly
                        </ToggleButton>
                        <ToggleButton value="All Time" sx={buttonSx}>
                            All Time
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', p: 2 }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                        variant="contained"
                        onClick={onClose}
                        sx={{
                            backgroundColor: '#ff3668',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#9c1a3b',
                            },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => onSave(selectedRange)}
                        sx={{
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#115c80',
                            },
                        }}
                    >
                        Save
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default SalesSettingsModal;