import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { loginUser } from '../contexts/api';
import { useAuth } from '../contexts/AuthContext';
import { Container, TextField, Button, Typography, Box, CircularProgress } from '@mui/material';
import Navbar from '../components/Navbar';
import { useNavigate } from 'react-router-dom';
import { LoginCredentials } from 'types/api.types';

function HomePage() {
  const { user, login, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user, navigate]);
  
  const [credentials, setCredentials] = useState<LoginCredentials>({ UserIdentifier: '', Password: '' });

  const mutation = useMutation({
    mutationFn: async (credentials: LoginCredentials) => {
      return loginUser(credentials);
    },
    onSuccess: (data) => {
      login(data);
      navigate('/dashboard');
    },
  });

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    mutation.mutate(credentials);
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="xs">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="100vh"
        >
          {user ? (
            <>
              <Typography variant="h5" component="h2">
                Welcome, {user.firstName || user.email}
              </Typography>
              <Button onClick={logout} variant="contained" color="primary" sx={{ mt: 2 }}>
                Logout
              </Button>
            </>
          ) : (
            <>
              <Typography variant="h4" component="h1" gutterBottom>
                Login
              </Typography>
              <form onSubmit={handleLogin} style={{ width: '100%' }}>
                <TextField
                  label="Email or Username"
                  type="text"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={credentials.UserIdentifier}
                  onChange={(e) => setCredentials({ ...credentials, UserIdentifier: e.target.value })}
                  disabled={mutation.isPending} // Disable inputs while loading
                />
                <TextField
                  label="Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={credentials.Password}
                  onChange={(e) => setCredentials({ ...credentials, Password: e.target.value })}
                  disabled={mutation.isPending} // Disable inputs while loading
                />
                <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                  {mutation.isPending ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ mt: 2 }}
                    >
                      Login
                    </Button>
                  )}
                </Box>
              </form>
            </>
          )}
        </Box>
      </Container>
    </>
  );
}

export default HomePage;
